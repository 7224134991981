<template>
  <div class="employee-replacement-view">
    <div class="employee-replacement-view__block">
      <esmp-select
        disabled
        placeholder="Регион"
      />
      <esmp-select
        disabled
        placeholder="Адрес"
        class="ml-10"
      />
    </div>
    <div class="employee-replacement-view__block mt-10">
      <esmp-select
        disabled
        placeholder="Этаж"
      />
      <esmp-select
        disabled
        placeholder="Комната"
        class="ml-10"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'EmployeeReplacementView',
  props: {
    settings: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="scss">
.employee-replacement-view {
  &__block {
    display: flex;
  }
}
</style>
